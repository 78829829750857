
export const Reducer = (state, action) => {

    switch (action.type) {
        case "add-all":
            return {
                ...state,
                all: action.payload
            }
        case "add-fruitSalad":
            return {
                ...state,
                fruitSalad: action.payload
            } 
        case "add-smoothieCustom":
            return {
                ...state,
                smoothieCustom: action.payload
            }             
        case "edit-allOrders":
            return {
                ...state,
                allOrders: [...state.allOrders, action.payload],
                
            };
        case "edit-orderType":
            return {
                ...state,
                orderType: [...state.orderType, action.payload]
            }
        case "open-checkout":
            return {
                ...state,
                openCheckout: true
            };
        case "close-checkout":
            return {
                ...state,
                openCheckout: false
            };
        case "edit-checkout":
            return {
                ...state,
                allOrders: [...action.payload]
            };
          
        case "clear-checkout":
            return {
                ...state,
                allOrders: []
            }
        
        default:
            return state;    
    }
}